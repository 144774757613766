import React from "react"
import Layout from "../components/layout"

export default ({ lang_config }) => {
  return (
    <Layout
      title="About"
      menu={lang_config.menu}
      description="About our application"
    >
      {false ? (
        <>
          <h4>The structure of .CSV file</h4>
          <p>
            The file must be in <strong>.csv</strong> format (not .excel) and
            contain two columns:
          </p>
          <p>
            <strong>time</strong>(col 1) and <strong>data</strong>(col 2), with
            the first row used as header.
          </p>
          <p>
            Here's an example: <a href="/sample.csv">sample.csv</a>
          </p>

          <hr />
        </>
      ) : null}

      <h4>Collaborators</h4>
      <ul>
        <li>Dr. Behrouz Far</li>
        <li>Dr. Amir Sanati-Nezhad</li>
        <li>Dr. Alireza Bahramian</li>
        <li>Dr. Hossein Hejazi</li>
        <li>Masoud Karimi (M.Sc.)</li>
      </ul>
    </Layout>
  )
}
